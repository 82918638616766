import { FC, useContext, useEffect, useMemo, useState } from 'react'
import confetti from 'canvas-confetti'
import { toast } from 'react-toastify'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import {
    Button,
    Input,
    Tabs,
    Tab,
    Tooltip,
    SelectInput,
    Select,
    Option,
    TextLink,
    ErrorMessage,
    DatePicker,
    NumberInput,
    Checkbox,
} from 'ds/components'
import { CfdiPaymentModule } from './CfdiPaymentModule'
import { SelectInvoicesInputLoader } from './SelectInvoicesInputLoader'

import { useForm } from 'lib/hooks/useForm'
import { paymentService } from 'lib/services/paymentService'
import { displayEmailCc } from 'lib/utils/displayEmailCc'
import { CfdiCreditModule } from './CfdiCreditModule'
import { formaDePago, formaDePagoCredit } from 'lib/constants/formaDePago'
import { BankTransaction } from 'lib/models/bankTransaction'
import {
    getCfdiIds,
    getInitAmount,
    getInitCurrency,
    getInitDate,
    getInvoicePaymentsMap,
    getInvoicesWithApplicableCfdis,
    getDefaultPaymentFormCode,
} from './utils'
import {
    dateStringToDate,
    dateToDateString,
    formatDateStringShort,
} from 'lib/utils/dateStringUtils'
import { SessionContext } from 'lib/hoc/withSession'
import { mutationOnError, mutationOnSuccess } from 'lib/utils/mutationUtils'
import { InvoicePaymentItem } from 'lib/models/InvoicePaymentItem'
import { Invoice } from 'lib/models/invoice'
import { Customer } from 'lib/models/customer'
import { GetExchangeRateHelp } from 'lib/common/GetExchangeRateHelp'
import { cfdiRelationshipsCredit } from 'lib/constants/cfdiRelationship'

const initialFormContext = {
    customer: { required: true },
    amount_paid: { required: true },
    currency: { required: true },
    payment_date: { required: true, type: 'dateString' as const },
    paymentFormObject: {
        getError: (v) => {
            if (!v.code) return 'Forma de pago es requerida'
            if (v.code === '99')
                return 'Forma de pago no puede ser "Por definir" al registrar un pago'
        },
    },
}

const colors = ['#0d6efd', '#ffffff']
const keysToInvalidate: string[][] = [
    ['customer'],
    ['subscription'],
    ['kpis'],
    ['invoice'],
    ['dashboard'],
    ['bank-transaction'],
    ['payment'],
]

type Props = {
    hideCreditPaymentOption?: boolean
    customers: Customer[]
    invoices: Invoice[]

    customer?: Customer
    bankTransaction?: BankTransaction

    onSuccess: () => void
}

export const RecordPaymentForm: FC<Props> = ({
    hideCreditPaymentOption,
    bankTransaction,
    invoices,
    customer,
    customers,
    onSuccess,
}) => {
    const queryClient = useQueryClient()
    const account = useContext(SessionContext).metadata.account

    const [isCredit, setIsCredit] = useState(false)

    const initializeWithInvoice = !!invoices?.[0]
    const initializeWithBankTransaction = !!bankTransaction

    const initialValues = useMemo(() => {
        const selectedInvoicedForPayment = getInvoicesWithApplicableCfdis(invoices, 'P')
        const defaultPaymentFormCode = getDefaultPaymentFormCode({
            isCredit,
            account,
        })

        return {
            customer: customer || null,
            amount_paid: getInitAmount({ invoices, bankTransaction }),
            currency: getInitCurrency({ invoice: invoices[0], bankTransaction }),
            exchange: null,
            exchange_dr: 0,
            paymentFormObject: formaDePago.find((o) => o.code === defaultPaymentFormCode),
            details: '',
            payment_date: getInitDate(bankTransaction),
            invoices: invoices?.[0] ? invoices : [],
            invoice_payments: [] as InvoicePaymentItem[], // only applicable if user chooses to split the payment manually
            send_email: !!invoices?.[0]?.contact?.email,
            generate_cfdi_pago: !!selectedInvoicedForPayment.length, // true if payment can have a payment cfdi
            credit_relationship: '01',
            generate_cfdi_egreso: false,
            split_payment_manually: false,
        }
    }, [customer, invoices, bankTransaction, isCredit, account])

    const {
        formData,
        formContext,
        formError,
        handleBlur,
        handleChange,
        setFormError,
        getFormError,
    } = useForm(initialValues, initialFormContext)

    const selectedInvoicedForPayment = useMemo(
        () => getInvoicesWithApplicableCfdis(formData.invoices, 'P'),
        [formData.invoices]
    )

    const selectedInvoicesForEgreso = useMemo(
        () => getInvoicesWithApplicableCfdis(formData.invoices, 'E'),
        [formData.invoices]
    )

    const invoiceCurrency = formData.invoices?.[0]?.currency
    const hasDifferentCurrencies = useMemo(() => {
        if (!invoiceCurrency) return false
        return formData.invoices.some((i) => i.currency !== invoiceCurrency)
    }, [formData.invoices, invoiceCurrency])

    useEffect(() => handleChange('exchange_dr', 0), [invoiceCurrency, handleChange])

    const currencyError = useMemo(() => {
        if (hasDifferentCurrencies) return 'No puedes pagar cobros con diferentes monedas'
        if (formData.currency === 'USD' && invoiceCurrency === 'MXN')
            return 'No puedes pagar en USD un cobro en MXN'
        if (formData.currency === 'MXN' && invoiceCurrency === 'USD' && isCredit)
            return 'No puedes crear crédito en MXN con un cobro en USD'

        return undefined
    }, [hasDifferentCurrencies, invoiceCurrency, formData.currency, isCredit])

    const invoicePaymentsError = useMemo(() => {
        // Empty rows
        if (
            formData.split_payment_manually &&
            formData.invoice_payments.some((i) => !i.amount || !i.invoice_id)
        ) {
            return 'No puedes dividir un pago con campos vacíos.'
        }
        return undefined
    }, [formData]) // Uses entire formData to track changes in invoice payments (nested objects)

    const { mutate, isPending } = useMutation({
        mutationFn: paymentService.postPayment,
        onError: mutationOnError(),
        onSuccess: mutationOnSuccess({
            onError: setFormError,
            onSuccess: ({ message }) => {
                toast.success(message)
                onSuccess()
                confetti({ colors })
                keysToInvalidate.forEach((key) =>
                    queryClient.invalidateQueries({ queryKey: key })
                )
            },
        }),
    })

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (
            getFormError() ||
            !formData.paymentFormObject ||
            !formData.customer ||
            currencyError ||
            invoicePaymentsError
        )
            return false

        mutate({
            bank_transaction_id: bankTransaction?.bank_transaction_id,
            amount_paid: formData.amount_paid,
            currency: formData.currency,
            exchange: formData.exchange,
            exchange_dr: formData.exchange_dr,
            details: formData.details,
            send_email: formData.send_email,
            payment_date: formData.payment_date,
            payment_form: formData.paymentFormObject.code,
            invoice_payments_map: getInvoicePaymentsMap(formData),
            customer_id: formData.customer.customer_id,
            generate_cfdi_pago: isCredit ? false : formData.generate_cfdi_pago,
            generate_cfdi_egreso: isCredit ? formData.generate_cfdi_egreso : false,
            is_credit: isCredit,
            credit_relationship: isCredit ? formData.credit_relationship : null,
            cfdi_ids_for_pago: getCfdiIds(selectedInvoicedForPayment),
            cfdi_ids_for_egreso: getCfdiIds(selectedInvoicesForEgreso, true),
            split_payment_manually: formData.split_payment_manually,
        })
    }

    const renderDateReceivedHelp = () => (
        <div className="d-flex gap-1">
            <span>{formatDateStringShort(formData.payment_date)}</span>-
            <TextLink
                onClick={() => handleChange('payment_date', dateToDateString(new Date()))}
            >
                hoy
            </TextLink>
            {invoices.length === 1 ? (
                <>
                    -
                    <TextLink
                        onClick={() =>
                            handleChange(
                                'payment_date',
                                dateToDateString(new Date(invoices[0].time_due))
                            )
                        }
                    >
                        vencimiento
                    </TextLink>
                </>
            ) : null}
        </div>
    )

    const setIsCreditTab = (e, isCredit_: boolean) => {
        e.stopPropagation()
        e.preventDefault()
        setIsCredit(isCredit_)

        const code = getDefaultPaymentFormCode({ isCredit: isCredit_, account })
        handleChange(
            'paymentFormObject',
            formaDePago.find((o) => o.code === code) || null
        )
    }

    const contact = formData.invoices?.[0]?.contact

    return (
        <form name="RecordPaymentForm" className="stacked-md" onSubmit={handleSubmit}>
            {hideCreditPaymentOption ? (
                ''
            ) : (
                <Tabs className="stacked-md" activeValue={isCredit}>
                    <Tab value={false} onClick={(e) => setIsCreditTab(e, false)}>
                        Pago
                    </Tab>
                    <Tab
                        disabled={
                            invoiceCurrency === 'USD' && formData.currency === 'MXN'
                        }
                        value
                        onClick={(e) => setIsCreditTab(e, true)}
                    >
                        Crédito
                    </Tab>
                </Tabs>
            )}
            <div className="row stacked-sm">
                <SelectInput
                    className="col-6"
                    disabled={!!customer}
                    formContext={formContext.customer}
                    label="Cliente"
                    value={formData.customer}
                    onChange={(value) => {
                        if (value?.customer_id === formData.customer?.customer_id) {
                            return
                        }
                        handleChange('customer', value)
                        handleChange('invoices', [])
                    }}
                    items={customers}
                    getItemId={(o) => o.customer_id}
                    itemToString={(o) => o && o.customer}
                />

                <Select
                    className="col"
                    disabled={
                        (initializeWithInvoice &&
                            (invoiceCurrency === 'MXN' || isCredit)) ||
                        initializeWithBankTransaction
                    }
                    formContext={formContext.currency}
                    label="Moneda"
                    value={formData.currency}
                    onChange={(value) => {
                        handleChange('currency', value)
                        handleChange('exchange', null)
                        handleChange('exchange_dr', 0)
                        if (initializeWithInvoice) handleChange('amount_paid', 0)
                    }}
                >
                    <Option value="MXN">MXN</Option>
                    <Option value="USD">USD</Option>
                </Select>
                {formData.currency === 'USD' ? (
                    <NumberInput
                        className="col"
                        decimalScale={4}
                        formContext={formContext.exchange}
                        label="Intercambio USD/MXN"
                        variant="currency"
                        value={formData.exchange}
                        onChange={(value) => handleChange('exchange', value)}
                        onBlur={() => handleBlur('exchange')}
                        help={
                            <GetExchangeRateHelp
                                onChange={(val) => handleChange('exchange', val)}
                            />
                        }
                    />
                ) : invoiceCurrency === 'USD' ? (
                    <NumberInput
                        className="col"
                        decimalScale={10}
                        formContext={formContext.exchange_dr}
                        label="Intercambio MXN/USD"
                        variant="currency"
                        value={formData.exchange_dr}
                        onChange={(value) => handleChange('exchange_dr', value)}
                        onBlur={() => handleBlur('exchange_dr')}
                    />
                ) : null}
            </div>
            <div className="row stacked-sm">
                <NumberInput
                    className="col-6"
                    disabled={initializeWithBankTransaction}
                    formContext={formContext.amount_paid}
                    label="Monto Total"
                    variant="currency"
                    value={formData.amount_paid}
                    onChange={(value) => handleChange('amount_paid', value)}
                    onBlur={() => handleBlur('amount_paid')}
                />
                <DatePicker
                    className="col-6"
                    label="Fecha"
                    formContext={formContext.payment_date}
                    value={dateStringToDate(formData.payment_date)}
                    onDateStringChange={(value) => handleChange('payment_date', value)}
                    onBlur={() => handleBlur('payment_date')}
                    help={renderDateReceivedHelp()}
                />
            </div>
            <div className="row stacked-sm gy-2">
                <SelectInput
                    className="col-sm-6"
                    formContext={formContext.paymentFormObject}
                    label="Forma de pago"
                    value={formData.paymentFormObject}
                    onChange={(o) => handleChange('paymentFormObject', o)}
                    items={isCredit ? formaDePagoCredit : formaDePago}
                    getItemId={(o) => o.code}
                    itemToString={(o) => o && `${o.code} - ${o.description}`}
                />
                {isCredit && selectedInvoicesForEgreso.length ? (
                    <Select
                        label="Relación"
                        className="col-sm-6"
                        value={formData.credit_relationship}
                        onChange={(value) => handleChange('credit_relationship', value)}
                    >
                        {CreditRelationshipOptions}
                    </Select>
                ) : null}
                <Input
                    className="col-sm"
                    label="Nota"
                    formContext={formContext.details}
                    value={formData.details}
                    onChange={(value) => handleChange('details', value)}
                    onBlur={() => handleBlur('details')}
                />
            </div>

            <SelectInvoicesInputLoader
                currencyError={currencyError}
                invoicePaymentsError={invoicePaymentsError}
                disabled={initializeWithInvoice}
                invoiceCurrency={invoiceCurrency}
                formData={formData}
                handleChange={(key: keyof typeof formData, value: any) =>
                    handleChange(key, value)
                }
            />
            <div className="stacked-sm">
                {contact ? (
                    <Checkbox
                        checked={formData.send_email}
                        disabled={!contact.email}
                        onChange={(value) => handleChange('send_email', value)}
                    >
                        Enviar por{' '}
                        <Tooltip trigger="email">
                            <div>
                                {contact.email
                                    ? `a: ${contact.email}`
                                    : 'Contacto no tiene email'}
                            </div>
                            <div>
                                {displayEmailCc({
                                    email_cc: contact.email_cc,
                                })}
                            </div>
                        </Tooltip>
                    </Checkbox>
                ) : null}

                {isCredit ? (
                    <CfdiCreditModule
                        disabled={!!currencyError}
                        formData={formData}
                        invoicesWithApplicableCfdis={selectedInvoicesForEgreso}
                        onChange={(value) => handleChange('generate_cfdi_egreso', value)}
                    />
                ) : (
                    <CfdiPaymentModule
                        disabled={!!currencyError}
                        formData={formData}
                        invoicesWithApplicableCfdis={selectedInvoicedForPayment}
                        onChange={(value) => handleChange('generate_cfdi_pago', value)}
                    />
                )}
            </div>
            <Button
                disabled={
                    !!currencyError || !formData.invoices.length || !!invoicePaymentsError
                }
                isLoading={isPending ? 'Registrando...' : undefined}
                type="submit"
            >
                {formData.send_email ? 'Registrar y enviar' : 'Registrar'}
            </Button>
            {formError ? <ErrorMessage className="mt-2">{formError}</ErrorMessage> : null}
        </form>
    )
}

const CreditRelationshipOptions = cfdiRelationshipsCredit.map(({ code, description }) => {
    return (
        <Option key={code} value={code}>
            {code} - {description}
        </Option>
    )
})
