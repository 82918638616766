import { downloadFile, makeApiRequest } from 'lib/utils/apiUtils/makeApiRequest'
import { formatDateTimestamp } from 'lib/utils/formatDateTimestamp'

const url = '/invoices'

export const invoiceService = {
    getInvoicesV2: async (queryParams) => {
        if (queryParams.download) {
            const filename = `cobros-${formatDateTimestamp(new Date())}.csv`
            return downloadFile({ url: `${url}/v2`, queryParams, filename })
        }
        return makeApiRequest({ url: `${url}/v2`, queryParams })
    },
    getInvoice: async ({ invoice_id }) => {
        if (!invoice_id) return false
        return makeApiRequest({ url: `${url}/${invoice_id}` })
    },
    postInvoice: async (body) => makeApiRequest({ url, method: 'POST', body }),
    putInvoice: async (body) =>
        makeApiRequest({
            url: `${url}/${body.invoice_id}`,
            method: 'PUT',
            body,
        }),
    postInvoicePaymentPlan: async (body) =>
        makeApiRequest({
            url: `${url}/${body.invoice_id}/payment-plan`,
            method: 'POST',
            body,
        }),
    postRetryFailedAutoChargePayment: async (body) =>
        makeApiRequest({
            url: `${url}/retry-payment`,
            method: 'POST',
            body,
        }),
    deleteInvoice: async (body) =>
        makeApiRequest({
            url: `${url}/${body.invoice_id}`,
            method: 'DELETE',
            body,
        }),
    downloadInvoicePdf: ({ invoice_id, series, invoice_num, settled_date }) => {
        const seriesAndInvoiceNum = [series, invoice_num].filter(Boolean).join(' ')
        const filename = `${
            settled_date ? 'Recibo' : 'Nota de venta'
        } - Folio ${seriesAndInvoiceNum}.pdf`
        return downloadFile({
            url: `${url}/${invoice_id}/sales-order`,
            queryParams: { download: true },
            filename,
        })
    },
    getInvoicePdfUrl: ({ invoice_id }) =>
        makeApiRequest({ url: `${url}/${invoice_id}/sales-order` }),
}
